import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What Is a Smart Contract?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The smart contract is a digital agreement that helps to automate the transactions as per the predefined conditions. Smart contracts are flexible & compatible for usage. They are widely used in various sectors like healthcare, IoT, and finance.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                How Much Does Smart Contract Development Cost?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The average cost for smart contract development will range up to $5000. But, this is not the exact cost for creation. It may vary based on various factors like feature integration, and project complexity.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                Which blockchain is used for smart contracts?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Ethereum is the best blockchain that is widely used for creating smart contracts in every business. Apart from that you can also utilize Solana, Waves, Polygon, BNB chain, and Tron blockchain networks.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How Much Does It Cost To Hire A Smart Contract Developer?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The exact amount may be around $45/per hour or more based on the developer’s expertise, experience, potentiality, and other factors. Approaching a reputed smart contract development company can help you to save money and time. If you are a newbie startup, hiring a smart contract developer will exceed your business budget.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How Long Does It Take To Develop A Smart Contract?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The smart contract development will take around 5 to 15 days depending on the developer team. Developing a smart contract patiently will give you more potential results. The Smart contract creation time limit may be exceeded based on various factors like development team, scalability nature, and feature integration.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>  
  )
}

export default FaqSection