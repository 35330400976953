import React from "react"

class Benificial extends React.Component {

  render() {
    return (
      <section className="smart-cont pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Smart Contract Development On <span className="bluecolor">Various Blockchain</span>
            </h2>
            <p className="text-center">
            Our developer team empowers your smart contract development with potential blockchain networks.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>Ethereum</h3>
                <p className="pharagraph">
                Our developer team offers cost-effective smart contract development solutions for the Ethereum blockchain network.
                </p>
              </div>
              <div className="square" >
                <h3>BNB Chain</h3>
                <p className="pharagraph">
                We provide numerous token standards for creating smart contracts on the BNB chain at an affordable cost.
                </p>
              </div>
              <div className="square" >
                <h3>Tron</h3>
                <p className="pharagraph">
                Our cutting-edge Tron smart contract development services offer scalable solutions for your business. 
                </p>
              </div>
              <div className="square" >
                <h3>Solana</h3>
                <p className="pharagraph">
                We offer exponential smart contracts in the Solana blockchain network that perform seamlessly as per user needs.
                </p>
              </div>
              <div className="square" >
                <h3>Polygon</h3>
                <p className="pharagraph">
                Our technical team offers exemplary smart contracts to integrate into polygon networks where they offer various use cases.
                </p>
              </div>
              <div className="square" >
                <h3>Waves</h3>
                <p className="pharagraph">
                We are experts in delivering Waves smart contract development that secures users' data into the blockchain.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
