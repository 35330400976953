import React from "react"

class Benificial extends React.Component {

  render() {
    return (
      <section className="fine-box smart pt-100 mb-0">
          <div className="gray-bg"> 
            <div className="container"> 
              <h2 className="heading-h2 text-center">Smart Contract Application Across <span className="bluecolor">Various Industries</span>
              </h2>
              <p className="text-center mb-2">
              Our smart contract development helps you harness success across numerous industries. We offer our hands to turn your crypto business into innovation.
              </p>
              <div className="d-lg-flex flex-wrap add-one">
                <div className="square" >
                  <h3>Finance</h3>
                  <p className="pharagraph">
                  Presently, smart contracts are adopted by the financial industry for seamless auditing, microlending, micro insurance, and trade clearing. Our smart contracts have the potential to automate transactions securely.
                  </p>
                </div>
                <div className="square" >
                  <h3>Voting Purposes</h3>
                  <p className="pharagraph">
                  Our smart contracts help to facilitate public data on the blockchain securely. DeFi protocols widely use smart contracts for voting purposes to get the necessary information from crypto users.
                  </p>
                </div>
                <div className="square" >
                  <h3>IoT</h3>
                  <p className="pharagraph">
                  Our developers integrate the smart contract into the BCoT (Blockchain of Things) to create and track new nodes for the blockchain. Our smart contracts are potential enough to keep the records in the supply chain.
                  </p>
                </div>
                <div className="square" >
                  <h3>Healthcare</h3>
                  <p className="pharagraph">
                  Our smart contracts are adopted in healthcare to maintain patient diagnosis, health tracking, and medical research. You can also utilize our smart contracts healthcare insurance where hacking is prevented.
                  </p>
                </div>
                <div className="square" >
                  <h3>Real Estate</h3>
                  <p className="pharagraph">
                  We develop smart contracts for real estate to automate property transfer and other rental services with end-to-end data encryption. Our team creates smart contracts that eliminate third-party interference.
                  </p>
                </div>
                <div className="square" >
                  <h3>Insurance</h3>
                  <p className="pharagraph">
                  Our smart contract development offers high-end security for insurance claiming, policy issuance, and enrolling. This helps your users to reduce unnecessary administrative costs and fraud. 
                  </p>
                </div>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default Benificial
