import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>Smart Contract</span> Development Company</h1>
                <p className="pharagraph"><b>Craft your crypto success together with us. </b></p>
                <p className='pharagraph mb-0'>At Coinsclone, We develop world-class smart contracts that meet your expectations for your business. Be the winning champion in the crypto field with our innovative smart contract development solutions.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/smart/smart-contract-development-company.webp"
                alt="Smart Contract Development Company image1"
                className='d-none d-md-block float-end'
                width={610}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection